body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  min-width: 360px;
}
.ant-form {
  width: 100%;
}
.ant-form-item-label {
  padding-bottom: 2px !important;
}
.ant-form label {
  color: #8c8c8c !important;
}
.ant-form-item {
  @media (max-width: 1080px) {
    margin-bottom: 16px;
  }
}
.ant-form:not(.withRows) .ant-form-item:last-child {
  margin-bottom: 0;
}
.ant-form-item-extra {
  font-size: 12px !important;
  line-height: 14px !important;
  margin-top: 4px;
  min-height: unset !important;
}

.ant-card-meta-title {
  white-space: pre-line !important;
}
.ant-select-item-option-content {
  white-space: break-spaces !important;
  word-break: break-word !important;
}

.ant-dd-menu-custom > .ant-dropdown-menu {
  margin-top: 12px;
  padding: 8px;
  border-inline-end: 1px solid rgba(5, 5, 5, 0.06);
}
.ant-dd-menu-custom > .ant-dropdown-menu > .ant-dropdown-menu-item {
  padding: 0;
  margin: 0;
  padding-inline-start: 16px;
  padding-inline-end: 16px;
  padding-left: 24px;
  margin-inline-start: 4px;
  margin-inline-end: 4px;
  margin-block-start: 4px;
  margin-block-end: 4px;
  overflow: hidden;
  height: 40px;
  line-height: 40px;
}
.ant-descriptions-title {
  font-weight: 400 !important;
}
.ant-collapse-header {
  padding-left: 0 !important;
}
.ant-upload {
  height: unset !important;
}
td.ant-table-cell {
  padding: 5px 16px !important;
}
th.ant-table-cell {
  font-weight: 400 !important;
  padding: 12px 16px !important;
}
td.ant-table-cell.withInputs {
  padding: 2px 4px !important;
}
.ant-descriptions-title {
  font-size: 14px !important;
  line-height: 22px !important;
}
.ant-tag {
  margin: 0;
}
.ant-table-summary .ant-table-cell:first-child {
  background: #fafafa;
}
.toolbar-wrapper {
  padding: 0px 24px;
  color: #fff;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}
.toolbar-wrapper .anticon {
  padding: 12px;
}
.ant-checkbox-group {
  display: flex;
  flex-direction: column;
}
